<template>
  <div class="table-responsive">
    <table class="table table-bordered table-hover table-business-rule-items">
      <thead class="table-primary">
        <tr>
          <th class="text-center cw-50">
            {{
              $t(
                "BaseModelFields.SerialNo",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th>
            {{
              $t(
                "BusinessRule.Name",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th>
            {{
              $t(
                "BusinessRule.BusinessRuleTriggerType",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th>
            {{
              $t(
                "BusinessRule.Description",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th>
            {{
              $t(
                "BusinessRule.BusinessProcess",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
          <th class="text-center cw-150">
            {{
              $t(
                "Buttons.Actions",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in businessRuleItems"
          :key="item.businessRuleModel.publicId"
        >
          <td class="align-middle text-center">
            <span class="badge align-middle badge-primary">
              {{ ++index }}
            </span>
          </td>
          <td class="align-middle">
            {{ item.businessRuleModel.name }}
          </td>
          <td class="align-middle">
            {{ item.businessRuleModel.businessRuleTriggerType }}
          </td>
          <td class="align-middle">
            {{ item.businessRuleModel.description }}
          </td>
          <td class="align-middle">
            {{ item.businessRuleModel.businessProcess }}
          </td>
          <td class="align-middle text-center">
            <button
              @click="getBusinessRuleActions(item)"
              type="button"
              class="btn btn-primary btn-no-custom-style me-2"
              data-bs-target="#modalBusinessRuleActionView"
              data-bs-toggle="modal"
            >
              <i class="bi bi-lightning-fill"> </i>
            </button>
            <a
              target="_blank"
              :href="
                String.format(
                  '#/CustomObject/EditBusinessRule/{0}&businessRuleId={1}',
                  item.businessRuleModel.customObjectPublicId,
                  item.businessRuleModel.publicId
                )
              "
              class="btn btn-warning btn-no-custom-style"
            >
              <i class="bi bi-pencil-square"> </i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <BusinessRuleActionViewModal :businessRule="currentBusinessRule" />
</template>
<script>
import BusinessRuleActionViewModal from "@/components/page-partials/business-rules/BusinessRuleActionViewModal.vue";
export default {
  name: "BusinessRuleTable",
  props: ["businessRuleItems"],
  data() {
    return {
      currentBusinessRule: null,
    };
  },
  methods: {
    getBusinessRuleActions(item) {
      this.currentBusinessRule = item;
    },
  },
  components: {
    BusinessRuleActionViewModal,
  },
};
</script>
