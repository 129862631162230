<template>
  <Breadcrumb v-if="customObject" :detailTitle="customObject.name" />
  <Breadcrumb v-else detailTitle="&nbsp;" />
  <div class="alert alert-info">
    <p
      class="text-center"
      v-html="
        this.$t(
          'BusinessRule.CacheDescription',
          {},
          { locale: this.$store.state.activeLang }
        ).replace('CUSTOMOBJECT_NAME', customObject.name)
      "
    ></p>
    <div class="w-100 text-center">
      <button
        type="button"
        :disabled="cacheReset"
        class="btn btn-warning"
        @click="cacheReload"
      >
        <i class="bi bi-arrow-counterclockwise"></i>
        {{
          $t(
            "BusinessRule.CacheReload",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ul class="nav nav-tabs mb-3" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active"
            id="all-tab"
            data-bs-toggle="tab"
            data-bs-target="#all"
            type="button"
            role="tab"
            aria-selected="true"
          >
            {{
              $t(
                "BaseModelFields.All",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="created-tab"
            data-bs-toggle="tab"
            data-bs-target="#created"
            type="button"
            role="tab"
            aria-controls="created"
            aria-selected="false"
          >
            {{
              $t(
                "BusinessRule.RecordCreated",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="updated-tab"
            data-bs-toggle="tab"
            data-bs-target="#updated"
            type="button"
            role="tab"
            aria-controls="updated"
            aria-selected="false"
          >
            {{
              $t(
                "BusinessRule.RecordUpdated",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="deleted-tab"
            data-bs-toggle="tab"
            data-bs-target="#deleted"
            type="button"
            role="tab"
            aria-controls="deleted"
            aria-selected="false"
          >
            {{
              $t(
                "BusinessRule.RecordDeleted",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="usertriggered-tab"
            data-bs-toggle="tab"
            data-bs-target="#usertriggered"
            type="button"
            role="tab"
            aria-controls="usertriggered"
            aria-selected="false"
          >
            {{
              $t(
                "BusinessRule.UserTriggered",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
        <li class="nav-item" role="presentation">
          <button
            class="nav-link"
            id="timetriggered-tab"
            data-bs-toggle="tab"
            data-bs-target="#timetriggered"
            type="button"
            role="tab"
            aria-controls="timetriggered"
            aria-selected="false"
          >
            {{
              $t(
                "BusinessRule.TimeTriggered",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </li>
      </ul>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="tab-content mt-1">
        <div
          class="tab-pane fade show active"
          id="all"
          role="tabpanel"
          aria-labelledby="all-tab"
        >
          <div v-if="allBusinessRules == null" class="alert alert-warning">
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <BusinessRuleTable :businessRuleItems="allBusinessRules" v-else />
        </div>
        <div
          class="tab-pane fade"
          id="created"
          role="tabpanel"
          aria-labelledby="created-tab"
        >
          <div v-if="createdBusinessRules == null" class="alert alert-warning">
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <BusinessRuleTable :businessRuleItems="createdBusinessRules" v-else />
        </div>
        <div
          class="tab-pane fade"
          id="updated"
          role="tabpanel"
          aria-labelledby="updated-tab"
        >
          <div v-if="updatedBusinessRules == null" class="alert alert-warning">
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <BusinessRuleTable :businessRuleItems="updatedBusinessRules" v-else />
        </div>
        <div
          class="tab-pane fade"
          id="deleted"
          role="tabpanel"
          aria-labelledby="deleted-tab"
        >
          <div v-if="deletedBusinessRules == null" class="alert alert-warning">
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <BusinessRuleTable :businessRuleItems="deletedBusinessRules" v-else />
        </div>
        <div
          class="tab-pane fade"
          id="usertriggered"
          role="tabpanel"
          aria-labelledby="usertriggered-tab"
        >
          <div
            v-if="userTriggeredBusinessRules == null"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <BusinessRuleTable
            :businessRuleItems="userTriggeredBusinessRules"
            v-else
          />
        </div>
        <div
          class="tab-pane fade"
          id="timetriggered"
          role="tabpanel"
          aria-labelledby="timetriggered-tab"
        >
          <div
            v-if="timeTriggeredBusinessRules == null"
            class="alert alert-warning"
          >
            {{
              $t(
                "BaseModelFields.NoRecords",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </div>
          <BusinessRuleTable
            :businessRuleItems="timeTriggeredBusinessRules"
            v-else
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import BusinessRuleTable from "@/components/page-partials/business-rules/BusinessRuleTable.vue";
export default {
  name: "Cache",
  data() {
    return {
      customObject: {},
      brCacheData: {},
      allBusinessRules: [],
      createdBusinessRules: [],
      updatedBusinessRules: [],
      deletedBusinessRules: [],
      userTriggeredBusinessRules: [],
      timeTriggeredBusinessRules: [],
      cacheReset: false,
    };
  },
  components: {
    BusinessRuleTable,
  },
  methods: {
    cacheReload() {
      this.$prodGatewayAxios
        .post(
          String.format(
            "/Brs-BusinessRuleSetByCustomObjectToCache?customObjectPublicId={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          if (response.data.isOk) {
            this.cacheReset = true;
            createToast(
              this.$t(
                "Messages.SuccessMessage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            createToast(
              this.$t(
                "Messages.ErrorMesage",
                {},
                { locale: this.$store.state.activeLang }
              ),
              {
                showIcon: "true",
                position: "top-right",
                type: "danger",
                transition: "zoom",
              }
            );
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getBrByCustomObjectCache() {
      var self = this;
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Brs-BusinessRuleGetByCustomObjectFromCache?customObjectPublicId={0}",
            self.$route.params.customObjectId
          )
        )
        .then((response) => {
          if (response.data.isOk) {
            self.brCacheData = response.data.businessRuleCacheModel;

            self.brCacheData.forEach((element) => {
              var currentTriggerType =
                element.businessRuleModel.businessRuleTriggerTypeId;

              self.allBusinessRules.push(element);
              if (currentTriggerType == 1) {
                self.createdBusinessRules.push(element);
              } else if (currentTriggerType == 2) {
                self.updatedBusinessRules.push(element);
              } else if (currentTriggerType == 3) {
                self.updatedBusinessRules.push(element);
                self.createdBusinessRules.push(element);
              } else if (currentTriggerType == 4) {
                self.deletedBusinessRules.push(element);
              } else if (currentTriggerType == 5) {
                self.timeTriggeredBusinessRules.push(element);
              } else if (currentTriggerType == 6) {
                self.userTriggeredBusinessRules.push(element);
              }
            });
          }
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getCustomObject() {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-CustomObjectGet?id={0}",
            this.$route.params.customObjectId
          )
        )
        .then((response) => {
          this.customObject = response.data;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
  },
  mounted() {
    this.getBrByCustomObjectCache();
    this.getCustomObject();
  },
};
</script>
