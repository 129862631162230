<template>
  <div
    class="modal fade"
    :id="`modalBusinessRuleActionView`"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    :aria-labelledby="`modalBusinessRuleActionViewLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="width: 90%; max-width: 90% !important">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5
            class="modal-title text-capitalize"
            :id="`modalBusinessRuleActionViewLabel`"
          >
            {{ businessRuleTitle }}
            {{
              $t(
                "Components.BusinessRuleActionViewModal.Title",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <Grid
            v-if="show"
            :settings="gridSettings"
            :columns="gridColumns"
            :rows="businessRuleActionList"
            @onEditButtonClick="onEditButtonClick"
          />
        </div>
        <div class="modal-footer p-4">
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="bi bi-x"></i>
            {{
              $t(
                "Components.BusinessRuleActionViewModal.Close",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "BusinessRuleActionViewModal",
  props: ["businessRule"],
  watch: {
    businessRule() {
      this.show = false;
      setTimeout(() => {
        this.show = true;
      }, 10);

      this.businessRuleTitle = this.businessRule.businessRuleModel.name;
      this.businessRuleActionList = this.businessRule.businessRuleActionDtos;
    },
  },
  components: {},
  data() {
    return {
      show: false,
      businessRuleTitle: "",
      businessRuleActionList: [],
      gridSettings: {
        action: "CustomObjectBusinessRuleActions",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [
          {
            key: "businessRuleActionId",
            value: "publicId",
            isRouteParameter: false,
          },
          {
            key: "businessRuleId",
            value: "businessRulePublicId",
            isRouteParameter: false,
          },
          {
            key: "customObjectId",
            value: "customObjectId",
            isRouteParameter: true,
          },
        ],

        allowSearchPanel: true,
        allowExcelExport: false,
        allowPaging: true,

        allowDeleting: false,
        allowDragAndDrop: false,
        autoOrderProcess: false,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BusinessRule.Name",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "name",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BusinessRuleActions.BusinessRuleActionType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "businessRuleActionType",
          type: "string",
          visible: true,
          width: 300,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "BaseModelFields.IsActive",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "isActive",
          type: "boolean",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
    };
  },
  methods: {
    onEditButtonClick(rowData) {
      var businessRuleId = rowData.businessRulePublicId,
        customObjectPublicId = this.$route.params.customObjectId,
        businessRuleActionId = rowData.publicId,
        routeName = "";

      localStorage.setItem(
        "businessRuleActionType",
        rowData.businessRuleActionType
      );

      if (rowData.businessRuleActionType == "CreateRecord") {
        routeName = "CustomObjectEditBusinessRuleActionCreateRecord";
      } else if (rowData.businessRuleActionType == "UpdateRecord") {
        routeName = "CustomObjectEditBusinessRuleActionUpdateRecord";
      } else if (rowData.businessRuleActionType == "SendNotification") {
        routeName = "CustomObjectEditBusinessRuleActionSendNotification";
      } else if (rowData.businessRuleActionType == "SendHTTPRequest") {
        routeName = "CustomObjectEditBusinessRuleActionSendHttpRequest";
      } else if (rowData.businessRuleActionType == "SendReport") {
        routeName = "CustomObjectEditBusinessRuleActionSendReport";
      } else if (rowData.businessRuleActionType == "SendXtraReport") {
        routeName = "CustomObjectEditBusinessRuleActionSendXtraReport";
      } else if (rowData.businessRuleActionType == "SendDashboard") {
        routeName = "CustomObjectEditBusinessRuleActionSendDashboard";
      }

      let route = this.$router.resolve({
        name: routeName,
        params: {
          customObjectId: customObjectPublicId,
          businessRuleId: businessRuleId,
          businessRuleActionId: businessRuleActionId,
        },
      });
      window.open(route.href);
    },
  },
};
</script>
